<template>
  <div class="home-wrap">
    <el-button
      size="small"
      type="primary"
      v-if="$store.state.user.authority === 1"
      @click="$router.push('/admin/review')"
      >返回审核列表</el-button
    >
    <el-steps class="step" :active="active" align-center>
      <el-step
        v-for="(item, index) in step"
        :key="index"
        :title="item.title"
        @click.native="handleStep(index)"
      />
    </el-steps>
    <el-alert
      class="alert"
      title="操作注意事项"
      type="warning"
      show-icon
      :closable="false"
    />
    <ul class="description">
      <li>
        <p>
          报名时间：<span>{{ matchTime[0] }} ~ {{ matchTime[1] }}</span
          >{{ timeName }}
        </p>
      </li>
      <!-- <li>1、出质前盖作者的著作权是否授权及授权情况说明：若未授权他人使用，填写“否”；若有授权情况，请在授权情况说明栏中填写著作权授权许可（包括专有和非专有）他人使用的有关情。</li> -->
      <!-- <li>2、软件为升级版本的，应在申请表软件基本信息栏中的软件作品说明中，选择“修改”并填写修改说明，前期版本已登记的应填写原登记号并提交原证件复印件。</li> -->
      <li>
        <p>
          如需查看详细报名规则，或查看系统使用手册，请点击“<span
            class="toNotice"
            title="点击跳转至比赛通知页面"
            @click="$router.push('/notice')"
            >比赛通知</span
          >”，下载并查阅相应附件
        </p>
      </li>
      <li>
        <p>
          填写过程中如有疑问，可拨打手机号码：<a href="tel:13246342016"
            >13246342016</a
          >
          进行咨询
        </p>
      </li>
    </ul>
    <router-view class="home-container" />
  </div>
</template>

<script>
import { getMatchTime } from "@/api/index.js";
import { getFullTimeNow } from "@/utils/utils.js";
export default {
  components: {},
  props: {},
  data() {
    return {
      // 比赛时间
      matchTime: "",
      // 报名状态
      timeName: "",
      // 定时器
      homeSetInterval: 0
    };
  },
  computed: {
    step() {
      if (this.$store.state.user.authority === 1) {
        return this.$store.state.project.stepArr.slice(0, -1);
      } else {
        return this.$store.state.project.stepArr;
      }
    },
    active: {
      get() {
        return this.$store.state.project.step;
      },
      set(val) {
        this.$store.commit("SET_STEP", val);
      }
    },
    status: {
      get() {
        return this.$store.state.project.status;
      },
      set(val) {
        this.$store.commit("SET_STATUS", val);
      }
    }
  },
  watch: {
    "$route.path"(val) {
      this.active = this.getStepIndex(val);
    },
    "$store.state": {
      deep: true,
      immediate: true,
      handler(val) {
        this.active = this.getStepIndex(this.$route.path);
        if (
          this.step[this.step.length - 1].path !== this.$route.fullPath &&
          val.project.status !== 0 &&
          val.user.authority === 0
        ) {
          this.$router.push(this.step[this.step.length - 1].path);
        }
      }
    }
  },
  created() {
    this.active = this.getStepIndex(this.$route.path);
    this.getMatchTime();
  },
  mounted() {
    // 一分钟更新一次
    this.homeSetInterval = setInterval(this.getMatchTime, 1000 * 60 * 2);
  },
  methods: {
    handleStep(index) {
      if (this.active === index || this.$store.state.user.authority !== 1) {
        return;
      }
      this.active = index;
      this.$router.push({
        path: this.step[index].path,
        query: this.$route.query
      });
    },
    setStep(index) {
      this.active = index;
    },
    getStepIndex(val) {
      let active = 0;
      this.$store.state.project.stepArr.forEach((element, index) => {
        if (val === element.path) {
          active = index;
        }
      });
      return active;
    },
    getMatchTime() {
      getMatchTime()
        .then(res => {
          this.matchTime = res.data;
          this.$store.commit("SET_MATCHTIME", res.data);
          let now = getFullTimeNow();
          if (now < this.matchTime[0]) {
            this.timeName = "（未开始报名）";
          } else if (now > this.matchTime[0] && now < this.matchTime[1]) {
            this.timeName = "（报名中）";
          } else {
            this.timeName = "（报名已结束）";
          }
        })
        .catch(err => {
          console.error(err);
        });
    }
  },
  beforeDestroy() {
    if (this.$store.state.user.authority === 1) {
      this.$store.commit("SET_MEMBERS", []);
      this.$store.commit("SET_AUTHOR", "");
    }
  }
};
</script>

<style scoped lang="scss">
.home-wrap {
  padding: 15px 30px 0;
}
.alert {
  margin-top: 20px;
}
.step {
  padding: 15px;
}
.description {
  font-size: 14px;
  padding: 0 10px;
  li {
    margin-bottom: 5px;
    p {
      font-size: 16px;
      span {
        color: #409eff;
      }
      span.toNotice:hover {
        cursor: pointer;
      }
    }
  }
  span {
    color: #409eff;
  }
  span:hover {
    text-decoration: underline;
  }
}
</style>
